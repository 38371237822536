<template>
  <div id="main_div">
    <!--    <div id="nav">-->
    <!--      <router-link to="/">Accueil</router-link>-->
    <!--      |-->
    <!--      <router-link to="/partA/progress" key="/partA/progress">L'Écoute</router-link>-->
    <!--      |-->
    <!--      <router-link to="/partB/progress" key="/partB/progress">L'Action</router-link>-->
    <!--    </div>-->
    <div class="bandeau_haut">
      <img src="/img/header.png" alt="Bannière haut"/>
    </div>
    <hr/>
    <router-view @nextsubpart="nextsubpart"/>
    <div v-if="userCode" class="user_code_display">Le code de votre partie est {{ userCode }}</div>
    <div class="copyright">© 2022-2024 Michel Crémadez / SimHO - Réalisation Argis - Conception graphique / illustrations
      Mathcz
    </div>
    <!--    <button id="clear_data" @click="clearData">Effacer les données</button>-->
    <!--    <button id="save_data" @click="saveData">Enregistrer les données</button>-->
    <!--    <button id="load_data" @click="loadData">Charger les données</button>-->
    <!--    <button id="new_code" @click="newCode">Nouveau code</button>-->
  </div>

</template>

<script>

// import axios from "axios";

const subparts = [
  '/about/partRA01',
  '/about/partRA02',
  '/about/partRA10',
  // 'partRA11',
  '/about/partRA12',
  '/about/partRA13',
  '/about/partRA14',
  '/partA/partA02',
  '/about/partRA20',
  '/partA/partA03',
  '/partA/partA04',
  '/partA/partA05',
  '/partA/partAR1',
  '/about/partRA30',
  '/partA/partA06',
  '/partA/partA07',
  '/partA/partA08',
  '/partA/partA09',
  '/partA/partA10',
  '/partA/partAR2',
  '/about/partRA40',
  '/partA/partA11',
  '/partA/partA12',
  '/partA/partAR3',
  '/about/conclusionA',
  '/about/partRB50',
  '/about/partRB52',
  '/about/partRB55',
  '/about/partRB56',
  // '/about/partRB57',
  '/partB/partB02',
  '/about/partRB60',
  '/partB/partB03',
  '/partB/partBR1',
  '/partC/partC01',
  '/partC/partC02',
  '/about/conclusionB',
  '/partC/partC03',
];

import {mapState} from 'vuex';

export default {
  name: 'App',
  async created() {
    let self = this;
    console.log('Starting "' + import.meta.env.VITE_NAME + '" in ' + import.meta.env.MODE);
    // console.log(import.meta)
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    const token = params.get('token');
    self.$store.commit('SET_TOKEN', token);
    self.$store.dispatch('clearProgression');
    self.$router.push('/'); //?token=' + token);

    // this.$store.dispatch('loadProgression');
    // this.clearData();
    /*setTimeout(function () {
      for (var i = 1; i <= 12; i++) {
        let img = new Image(), src = '/img/partA';
        if (i < 10) {
          src += '0';
        }
        src += i + '.png';
        console.log(src);
        img.src = src;
        /*let src = '/img/partA';
        if (i < 10) {
          src += '0';
        }
        import(src + '.png');* /
      }
    }, 100);*/
  },
  computed: {
    ...mapState({
      userCode: state => state.code,
    })
  },
  methods: {
    /*clearData: function (/!*evt*!/) {
      this.$store.dispatch('clearProgression');
      location.reload();
    },
    saveData: function (/!*evt*!/) {
      this.$store.dispatch('sendProgression');
    },
    loadData: function (/!*evt*!/) {
      this.$store.dispatch('loadProgression');
    },
    newCode: async function () {
      try {
        if (localStorage.getItem('expat.code')) {
          if (confirm('Voulez-vous continuer avec le code existant ?')) {
            return;
          }
        }
        const {data} = await this.$http.post(
            'https://expat.loc/api.html',
            {name: "my item"},
            {headers: {'X-Requested-With': 'XMLHttpRequest', 'X-Requested-Type': 'json', 'X-Remote-Call': 'GetCode'}}
        );
        console.log('OK', data);
        if (data.status === 'OK') {
          alert('Votre code est ' + data.code);
          localStorage.setItem('expat.code', data.code);
        }
      } catch (err) {
        console.log('ERR', err);
      } finally {
        console.log('DONE');
      }
    },*/
    nextsubpart(current_subpart) {
      console.log('Top level nextsubpart');
      const idx = subparts.indexOf(current_subpart);
      console.log('Next subpart ' + idx, current_subpart);
      if ((idx >= 0) && (idx < subparts.length - 1)) {
        // this.progression[this.subpart_].done = true;
        // this.$store.dispatch('saveProgression');
        // window.location = '/partA/' + subparts[idx + 1];
        let url = subparts[idx + 1];
        if (!url.startsWith('/')) {
          url = '/' + this.$options.name + '/' + url;
        }
        this.$store.dispatch('saveProgression', {current: url});
        this.$store.dispatch('sendProgression');
        console.log('Moving to ' + url);
        this.$router.push(url);
        if (idx < subparts.length - 2) {
          console.log('Prefetch for ' + subparts[idx + 2]);
        }
      }
      /*if (idx === subparts.length - 1) {
        this.$router.push('/partA/partA02');
      }*/
    }
  }
}
</script>

<style>

/*#nav {*/
/*  display: none;*/
/*}*/

/*noinspection CssUnknownTarget*/
/*@import url('https://fonts.googleapis.com/css2?family=Glory&display=swap');*/

#app {
  font-family: Montserra, Glory, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
}

#main_div {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

#main_div IMG, .bandeau_haut IMG {
  width: 100%;
}

/*H1 {
  margin: 0;
  padding: 0;
}

H2 {
  margin: 10px;
}*/


.copyright {
  font-family: Montserrat;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  font-size: 0.8em;
  color: #755B3D;
  clear: both;
}

.copyright:before {
  content: url("/img/simho-s.png");
  display: block;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


</style>
