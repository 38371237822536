import { createRouter, createMemoryHistory } from 'vue-router'
import Home from '../views/Home.vue';
// import About from '../views/About.vue';

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Home
  },
  {
    path: '/about/:subpart?',
    name: 'Règle du jeu',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/partA/:subpart?',
    name: 'L\'Écoute',
    component: () => import(/* webpackChunkName: "partA" */ '../views/partA.vue'),
    props: true
  },
  {
    path: '/partB/:subpart?',
    name: 'L\'Action',
    component: () => import(/* webpackChunkName: "partB" */ '../views/partB.vue'),
    props: true
  },
  {
    path: '/partC/:subpart?',
    name: 'Questionnaires',
    component: () => import(/* webpackChunkName: "partC" */ '../views/partC.vue'),
    props: true
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createMemoryHistory(),
  routes
});


// router.beforeEach((to, from) => {
//   console.log('Guard', to, from);
//   return true;
// });

window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
    e.returnValue = '';
});

export default router
