<template>
  <div>
    <div class="home">
      <img alt="Main illustration" src="/img/page_garde.png"/>
      <!--p>L’expérience patient c’est « L’ensemble des interactions et des situations vécues par une personne ou son
        entourage au cours de son parcours de santé. Ces interactions sont façonnées à la fois par l’organisation de ce
        parcours mais aussi par l’histoire de vie de la personne concernée. »</p-->

      <div id="loading_div" v-if="loading">
        <semipolar-spinner
            :animation-duration="2000"
            :size="65"
            color="#bb9171"
        />
        <p>Chargement...</p>
      </div>

      <div v-if="message" class="token_error">
        <h1>Impossible de démarrer le jeu</h1>
        <p v-if="err === 'bad_token'">Pour pouvoir jouer, vous devez utiliser le lien (URL) fourni par votre
          établissement
          ou qui vous a été donné lors de votre inscription.</p>
        <p>{{ message }}</p>
      </div>

      <div v-if="token && !loading">

        <form v-if="token && !url" @submit.prevent="startGame" class="intro_overtext">
          <p class="clientName">{{ clientName }}</p>
          <p class="urlLabel" v-if="urlLabel">{{ urlLabel }}</p>
          <span>Si vous avez déjà commencé une partie, indiquez votre code ici :</span>
          <br>
          <input type="text" v-model="code" autofocus>
          <span v-if="err" class="code_err">Ce code est incorrect</span>
          <br>
          <span>Si vous commencez une nouvelle partie, cliquez simplement sur Démarrer</span>
          <br>
          <!--      <input type="submit" value="Démarrer">-->
        </form>

        <div v-if="token && url" class="intro_overtext">
          <span>Votre code est le</span><br>
          <span class="show_code">{{ code }}</span><br>
          <span>Pensez à bien le noter au cas où vous auriez besoin d'interrompre votre partie.</span>
        </div>
      </div>

      <hr/>
      <!--    <div class="bottom_buttons">-->
      <!--      <button class="big" @click="$router.push('/about/partRA01')">Suivant</button>-->
      <!--    </div>-->
    </div>
    <div class="bottom_buttons" v-if="token && !loading">
      <button v-if="!url" @click.prevent="startGame" class="big">Démarrer</button>
      <button v-if="url" @click.prevent="next" class="big">Suivant</button>
    </div>
  </div>
</template>

<script>

import {SemipolarSpinner} from 'epic-spinners';


export default {
  name: 'Accueil ExpPat',
  data() {
    return {
      err: '',
      message: '',
      code: '',
      url: '',
      token: '',
      clientName: '',
      urlLabel: '',
      loading: true
    }
  },
  components: {
    SemipolarSpinner
  },
  async created() {
    console.log('Created');
    this.$store.dispatch('checkToken', this);
  },
  methods: {
    async next() {
      this.loading = true;
      await this.$router.push(this.url);
    },
    async startGame() {
      this.loading = true;
      this.$store.dispatch('startGame', this);
    }
  },
  /*watch: {
    loading(newValue, oldValue) {
      console.log('Input value changed:', oldValue, '->', newValue);
    }
  }*/
}
</script>


<style scoped>
.home {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

.home IMG {
  max-width: 100%;
}

.code_err {
  color: #cb260b;
}

.intro_overtext {
  /*position: relative;*/
  /*top: -95px;*/
  background-color: #e8e6bd;
  padding: 30px;
  text-align: center;
}

.intro_overtext INPUT {
  border: 1px solid #FF8242;
  padding: 10px;
  border-radius: 8px;
}

.intro_overtext * {
  margin: 15px;
}

.show_code {
  font-size: 120%;
  border: 1px solid #FF8242;
  color: #FF8242;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  line-height: 3;
}

.token_error {
  text-align: center;

  font-size: 120%;
  border: 1px solid #FF8242;
  border-radius: 8px;
  background-color: #f3bf8c;
  padding: 5%;
}

.token_error * {
  color: #cb260b;
  text-align: center;
}

.clientName {
  color: #FF8242;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 160%;
}

.urlLabel {
  color: #FF8242;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
  font-size: 120%;
}

#loading_div {
  margin: 30px;
}

#loading_div * {
  margin: auto;
  text-align: center;
}
</style>